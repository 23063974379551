var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"timer-content"},[_c('div',{staticClass:"count-down-content-black",class:{
          'box-square-black': !_vm.isIpadPro(),
          'box-square-black-ipad': _vm.isIpadPro(),
        }},[_c('div',{staticClass:"content text-center"},[(!_vm.timeLeft)?[_c('div',{staticClass:"timing-row"},[_c('v-row',_vm._b({},'v-row',{
                  justify: 'center',
                },false),[_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',_vm._b({staticClass:"flex-column text-center"},'v-row',{
                      justify: 'center',
                      'no-gutters': true,
                    },false),[_c('div',{staticClass:"text-center label-timer"},[_vm._v(" -- ")]),_c('div',{staticClass:"label-unit text-center"},[_vm._v(" วัน ")])])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',_vm._b({staticClass:"flex-column text-center"},'v-row',{
                      justify: 'center',
                      'no-gutters': true,
                    },false),[_c('div',{staticClass:"text-center label-timer"},[_vm._v(" -- ")]),_c('div',{staticClass:"label-unit text-center"},[_vm._v(" ชั่วโมง ")])])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',_vm._b({staticClass:"flex-column text-center"},'v-row',{
                      justify: 'center',
                      'no-gutters': true,
                    },false),[_c('div',{staticClass:"text-center label-timer"},[_vm._v(" -- ")]),_c('div',{staticClass:"label-unit text-center"},[_vm._v(" นาที ")])])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',_vm._b({staticClass:"flex-column text-center"},'v-row',{
                      justify: 'center',
                      'no-gutters': true,
                    },false),[_c('div',{staticClass:"text-center label-timer"},[_vm._v(" -- ")]),_c('div',{staticClass:"label-unit text-center"},[_vm._v(" วินาที ")])])],1)],1)],1),_c('v-row',_vm._b({staticClass:"text-incoming-black"},'v-row',{ justify: 'center' },false),[_vm._v(" กรุณารอสักครู่ แคมเปญจะเปิดให้บริการในเร็วๆ นี้ ")]),_c('div',{staticClass:"social-black"},[_c('a',{attrs:{"target":"_blank","href":"https://www.facebook.com/Bananaitshop"}},[_c('font-awesome-icon',{attrs:{"icon":{
                    prefix: 'fab',
                    iconName: 'facebook-f',
                  }}})],1),_c('a',{attrs:{"target":"_blank","href":"https://www.instagram.com/banana_ig"}},[_c('font-awesome-icon',{attrs:{"icon":{
                    prefix: 'fab',
                    iconName: 'instagram',
                  }}})],1)])]:[_c('countdown',{attrs:{"time":_vm.timeLeft},on:{"end":_vm.onEndCountdown},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"timing-row"},[_c('v-row',_vm._b({},'v-row',{ justify: 'center' },false),[_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',_vm._b({staticClass:"flex-column text-center"},'v-row',{
                          justify: 'center',
                          'no-gutters': true,
                        },false),[_c('div',{staticClass:"text-center label-timer"},[_vm._v(" "+_vm._s(_vm.pad(props.days))+" ")]),_c('div',{staticClass:"label-unit text-center"},[_vm._v(" วัน ")])])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',_vm._b({staticClass:"flex-column text-center"},'v-row',{
                          justify: 'center',
                          'no-gutters': true,
                        },false),[_c('div',{staticClass:"text-center label-timer"},[_vm._v(" "+_vm._s(_vm.pad(props.hours))+" ")]),_c('div',{staticClass:"label-unit text-center"},[_vm._v(" ชั่วโมง ")])])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',_vm._b({staticClass:"flex-column text-center"},'v-row',{
                          justify: 'center',
                          'no-gutters': true,
                        },false),[_c('div',{staticClass:"text-center label-timer"},[_vm._v(" "+_vm._s(_vm.pad(props.minutes))+" ")]),_c('div',{staticClass:"label-unit text-center"},[_vm._v(" นาที ")])])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',_vm._b({staticClass:"flex-column text-center"},'v-row',{
                          justify: 'center',
                          'no-gutters': true,
                        },false),[_c('div',{staticClass:"text-center label-timer"},[_vm._v(" "+_vm._s(_vm.pad(props.seconds))+" ")]),_c('div',{staticClass:"label-unit text-center"},[_vm._v(" วินาที ")])])],1)],1)],1),_c('v-row',_vm._b({staticClass:"text-incoming-black"},'v-row',{ justify: 'center' },false),[_vm._v(" กรุณารอสักครู่ แคมเปญจะเปิดให้บริการในเร็วๆ นี้ ")]),_c('div',{staticClass:"social-black"},[_c('a',{attrs:{"target":"_blank","href":"https://www.facebook.com/Bananaitshop"}},[_c('font-awesome-icon',{attrs:{"icon":{
                        prefix: 'fab',
                        iconName: 'facebook-f',
                      }}})],1),_c('a',{attrs:{"target":"_blank","href":"https://www.instagram.com/banana_ig"}},[_c('font-awesome-icon',{attrs:{"icon":{
                        prefix: 'fab',
                        iconName: 'instagram',
                      }}})],1)])]}}])})]],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <!-- <p>TODO: @first add countdown here</p> -->
  <div class="no-campaign text-3xl font-semibold">
    <div
      class="desc-no-campaign text-dark-gray text-desc-no-campaign"
    >
      <CountDown />
    </div>
  </div>
</template>

<script>
import CountDown from '@/components/PlayStation/CountDown'
export default {
  name: 'Incoming',

  components: {
    CountDown,
  },
}
</script>

<style lang="stylus" scoped>
.no-campaign
  position block
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  height: 100%
  flex-direction: column
.desc-no-campaign
  width: 100%
.text-desc-no-campaign
    font-size: 1.875rem
@media screen and (max-width: 768px)
    .text-desc-no-campaign
        font-size: 1rem
</style>

export const embedYoutube = (youtubeId, start, end) => {
  //   console.log(start, end)
  //   var x = window.scrollX
  //   var y = window.scrollY
  //   window.onscroll = function() {
  //     window.scrollTo(x, y)
  //   }
  //   document.ontouchmove = function(e) {
  //     e.preventDefault()
  //   }
  var tag = document.createElement('script')
  tag.id = 'iframe-demo'
  tag.src = 'https://www.youtube.com/iframe_api'
  var firstScriptTag = document.getElementsByTagName('script')[0]
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
  let player
  const playerConfig = {
    width: window.screen.width,
    height: window.screen.height,
    videoId: youtubeId,
    allowfullscreen: 1,
    playerVars: {
      version: 3,
      autoplay: 1,
      mute: 1,
      modestbranding: 0,
      autohide: 0,
      showinfo: 0,
      showtitle: 0,
      controls: 0,
      loop: 1,
      rel: 0,
      start: start,
      end: end,
    },
    events: {
      onReady: evt => {
        evt.target.playVideo()
      },
      onStateChange: state => {
        if (state.data === window.YT.PlayerState.ENDED) {
          player.loadVideoById({
            videoId: youtubeId,
            startSeconds: start,
            endSeconds: end,
          })
        }
      },
    },
  }

  window.onYouTubeIframeAPIReady = () => {
    // console.log('onYouTubeIframeAPIReady')
    player = new window.YT.Player('youtube', playerConfig)
  }
}

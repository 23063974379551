<template>
  <div>
    <div>
      <div class="timer-content">
        <div
          v-bind:class="{
            'box-square-black': !isIpadPro(),
            'box-square-black-ipad': isIpadPro(),
          }"
          class="count-down-content-black"
        >
          <div class="content text-center">
            <template v-if="!timeLeft">
              <div class="timing-row">
                <v-row
                  v-bind="{
                    justify: 'center',
                  }"
                >
                  <v-col cols="3">
                    <v-row
                      v-bind="{
                        justify: 'center',
                        'no-gutters': true,
                      }"
                      class="flex-column text-center"
                    >
                      <div class="text-center label-timer">
                        --
                      </div>
                      <div class="label-unit text-center">
                        วัน
                      </div>
                    </v-row>
                  </v-col>
                  <v-col cols="3">
                    <v-row
                      v-bind="{
                        justify: 'center',
                        'no-gutters': true,
                      }"
                      class="flex-column text-center"
                    >
                      <div class="text-center label-timer">
                        --
                      </div>
                      <div class="label-unit text-center">
                        ชั่วโมง
                      </div>
                    </v-row>
                  </v-col>
                  <v-col cols="3">
                    <v-row
                      v-bind="{
                        justify: 'center',
                        'no-gutters': true,
                      }"
                      class="flex-column text-center"
                    >
                      <div class="text-center label-timer">
                        --
                      </div>
                      <div class="label-unit text-center">
                        นาที
                      </div>
                    </v-row>
                  </v-col>
                  <v-col cols="3">
                    <v-row
                      v-bind="{
                        justify: 'center',
                        'no-gutters': true,
                      }"
                      class="flex-column text-center"
                    >
                      <div class="text-center label-timer">
                        --
                      </div>
                      <div class="label-unit text-center">
                        วินาที
                      </div>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <v-row
                class="text-incoming-black"
                v-bind="{ justify: 'center' }"
              >
                กรุณารอสักครู่ แคมเปญจะเปิดให้บริการในเร็วๆ นี้
              </v-row>
              <div class="social-black">
                <a
                  target="_blank"
                  href="https://www.facebook.com/Bananaitshop"
                >
                  <font-awesome-icon
                    :icon="{
                      prefix: 'fab',
                      iconName: 'facebook-f',
                    }"
                  />
                </a>

                <a
                  target="_blank"
                  href="https://www.instagram.com/banana_ig"
                >
                  <font-awesome-icon
                    :icon="{
                      prefix: 'fab',
                      iconName: 'instagram',
                    }"
                  />
                </a>
              </div>
            </template>
            <template v-else>
              <countdown :time="timeLeft" @end="onEndCountdown">
                <template slot-scope="props">
                  <div class="timing-row">
                    <v-row v-bind="{ justify: 'center' }">
                      <v-col cols="3">
                        <v-row
                          v-bind="{
                            justify: 'center',
                            'no-gutters': true,
                          }"
                          class="flex-column text-center"
                        >
                          <div class="text-center label-timer">
                            {{ pad(props.days) }}
                          </div>
                          <div class="label-unit text-center">
                            วัน
                          </div>
                        </v-row>
                      </v-col>
                      <v-col cols="3">
                        <v-row
                          v-bind="{
                            justify: 'center',
                            'no-gutters': true,
                          }"
                          class="flex-column text-center"
                        >
                          <div class="text-center label-timer">
                            {{ pad(props.hours) }}
                          </div>
                          <div class="label-unit text-center">
                            ชั่วโมง
                          </div>
                        </v-row>
                      </v-col>
                      <v-col cols="3">
                        <v-row
                          v-bind="{
                            justify: 'center',
                            'no-gutters': true,
                          }"
                          class="flex-column text-center"
                        >
                          <div class="text-center label-timer">
                            {{ pad(props.minutes) }}
                          </div>
                          <div class="label-unit text-center">
                            นาที
                          </div>
                        </v-row>
                      </v-col>
                      <v-col cols="3">
                        <v-row
                          v-bind="{
                            justify: 'center',
                            'no-gutters': true,
                          }"
                          class="flex-column text-center"
                        >
                          <div class="text-center label-timer">
                            {{ pad(props.seconds) }}
                          </div>
                          <div class="label-unit text-center">
                            วินาที
                          </div>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                  <v-row
                    class="text-incoming-black"
                    v-bind="{ justify: 'center' }"
                  >
                    กรุณารอสักครู่ แคมเปญจะเปิดให้บริการในเร็วๆ นี้
                  </v-row>
                  <div class="social-black">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/Bananaitshop"
                    >
                      <font-awesome-icon
                        :icon="{
                          prefix: 'fab',
                          iconName: 'facebook-f',
                        }"
                      />
                    </a>

                    <a
                      target="_blank"
                      href="https://www.instagram.com/banana_ig"
                    >
                      <font-awesome-icon
                        :icon="{
                          prefix: 'fab',
                          iconName: 'instagram',
                        }"
                      />
                    </a>
                  </div>
                </template>
              </countdown>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loadcampaign from '../../utils/loadcampaign'
import { embedYoutube } from '@/utils/youtube'
import axios from '@/config/api'
import { mapState } from 'vuex'

export default {
  name: 'CountDown',
  computed: {
    ...mapState({
      campaign: state => state?.campaign?.current?.data,
      startTime: state =>
        state?.campaign?.current?.data?.started_at_timestamp ||
        undefined,
      currentTime: state =>
        state?.campaign?.current?.data?.current_datetime_timestamp ||
        undefined,
    }),

    timeLeft() {
      if (this.startTime && this.currentTime) {
        return (this.startTime - this.currentTime) * 1000
      }
      return null
    },
  },

  data() {
    return {
      timeCount: undefined,
      timer: '',
      dd: '--',
      hh: '--',
      mm: '--',
      ss: '--',
      player: null,
      isPlayYoutube: window.screen.width > 1024 && !this.isIpadPro(),
      section: {
        start: 0,
        end: 30,
      },
    }
  },

  watch: {
    // timeCount(val) {
    //   if (val <= 0) {
    //     this.dd = '00'
    //     this.hh = '00'
    //     this.mm = '00'
    //     this.ss = '00'
    //     this.$router.push({ name: 'PS5' })
    //   }
    // },
  },

  async mounted() {
    this.getCampaign()

    // TODO: Deprecated
    // const self = this
    // setInterval(function() {
    //   if (self.startTime && self.timeCount === undefined) {
    //     self.timeCount = self.startTime - self.currentTime
    //   } else {
    //     if (self.timeCount >= 0) self?.updateETime()
    //   }
    // }, 1000)
  },

  methods: {
    loadcampaign,
    embedYoutube,
    isIpadPro() {
      return (
        /MacIntel/.test(navigator.platform) &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2
      )
    },
    pad(num) {
      return num > 9 ? num : '0' + num
    },

    updateETime() {
      const days = Math.floor(this.timeCount / 86400)
      const hours = Math.floor((this.timeCount / 3600) % 24)
      const mins = Math.floor(this.timeCount / 60) % 60
      const secs = Math.floor(this.timeCount) % 60
      this.dd = this.pad(days)
      this.hh = this.pad(hours)
      this.mm = this.pad(mins)
      this.ss = this.pad(secs)
      if (this.timeCount > 0) this.timeCount -= 1 // Every time the function runs, subtract one second from diff.
    },

    async getCampaign() {
      try {
        const inComingRoute = 'ps5'
        const result = await axios.get(`/campaigns/${inComingRoute}`)
        const val = result?.data || null
        if (val?.data?.id && val?.data?.status === 'incoming') {
          val.data.isHome = val?.data?.is_delivery_home
          val.data.isShop = val?.data?.is_delivery_store
          this.$store.commit('cart/delivery', val?.data?.isHome)
          this.$store.dispatch('campaign/update', val)
          this.$store.commit('campaign/updateLoading', false)
        } else {
          this.$router.push({
            name: 'Error',
            params: { error: 'notfound' },
          })
        }
      } catch (e) {
        window.location.href = '/error/notfound'
      }
    },

    onEndCountdown() {
      setTimeout(async () => {
        this.$router.push({ name: 'PS5' })
      }, 300)
    },
  },
}
</script>

<style lang="stylus">

.prevent
    position : absolute
    z-index : 100
    background : black
    width : 100vw
    height : 100vh
    top : 0
    opacity : 0

.link-social {
    color white
}

.video-container {
  position: relative;
}

.timing-margin {
    margin-top : 40px
}

.timing-row {
    display: block
}

.video
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  border: 0 !important;


.footer-count-foreground
  background white
  width: 100%
  position: fixed
  display: flex
  bottom: 0
  height: 60px
  color: white
  z-index 11

.footer-count-down
  background transparent
  width: 100%
  position: fixed
  display: flex
  bottom: 0
  height: 60px
  color: white
  z-index 50
  font-size 16px

.timer-content
    display flex
    justify-content center

.count-down-content-black
    color: black
    flex-direction: column
    z-index: 120;
    display: flex
    justify-content: center
    align-items: center
    // height: 25vh
    font-size: 7rem
    font-weight: bold
    width: 100%

.count-down-content
    color: white
    flex-direction: column
    z-index: 120;
    display: flex
    justify-content: center
    align-items: center
    height: 25vh
    font-size: 7rem
    font-weight: bold
    width: 100%
.label-timer
    z-index: 120;
    margin-bottom: -30px
.label-unit
    z-index: 120;
    font-size: 1.75rem
.box-square
    position: relative;
    background: rgba(0,0,0,0);
    padding 1rem;
    z-index: 120;
    width 45%
.box-square .content {
    display: block;
    width: 100%;
    position: relative;
    z-index: 1;
    flex-direction: column;
}
.box-square::before {
    content: "";
    top: 25px; right: 25px; left: 25px;
    position: absolute;
    display: block;
    box-shadow: 0 0.2em 4em 1.5em rgba(0,0,0,0.3);
}

box-square-black-ipad
    position: relative;
    padding 1rem;
    width 75%

.box-square-black {
    position: relative;
    padding 1rem;
    max-width 700px;
}

.box-square-black .content {
    display: flex;
    width: 100%;
    position: relative;
    z-index: 1;
    flex-direction: column;
}
.box-square-black::before {
    content: "";
    display: block;
}
.timer-box
    margin-left -12px
    margin-right -12px
.text-incoming
    color: white
    font-size 1.75rem
    z-index: 120;
    text-align: center;
    margin-top: 110px
.text-incoming-black
    color: black
    font-size 1.75rem
    z-index: 120;
    text-align: center;
    margin-top: 110px
.social
    color: white !important
    margin-top: -55px
.social a
    color: white !important
.social a svg
    margin -12px 35px
    width 40px !important
    height 40px !important
.social-black
    color: black !important
    margin-top: -40px
.social-black a
    color: black !important
.social-black a svg
    margin 0px 35px
    width 40px !important
    height 40px !important

@media screen and (max-width: 768px)
    .timer-box
        margin-left 0px
        margin-right 0px
    .count-down-content
        font-size: 2rem
    .count-down-content-black
        font-size: 2rem
    .label-timer
        font-size: 3.5rem
        margin-bottom: 0px
    .label-unit
        font-size: 1.2rem
    .text-incoming
        font-size 0.875rem
        margin-top: 8rem
    .text-incoming-black
        font-size 0.875rem
        margin-top: 80px
    .box-square
        width 80%
    .box-square-black
        width 100%
    .social
        margin-top: 20px
    .social a svg
        margin 0px 20px
        height 25px !important
        width 25px !important
    .social-black
        margin-top: 35px
    .social-black a svg
        margin 0px 20px
        height 25px !important
        width 25px !important

@media screen and (max-width: 1024px)
    .timer-content
        margin-top: -120px
    .timing-margin
        top: 20
        margin-top : 0rem
    .box-square
        width 80%
    .box-square-black
        width 90%

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)
    .timer-content
        margin-top: 0px
    .box-square-black .content
        width: 75%;
    .timing-row
        display: block
        margin-top: 0px
    .text-incoming-black
        font-size: 1rem
        margin-top: 50px
    .count-down-content-black
        font-size: 1.6rem
    .label-timer
        font-size: 3.3rem
        margin-bottom: 0px
    .social
        margin-top: -10px
    .social a svg
        margin 0px 0px
        height 25px !important
        width 25px !important
    .social-black
        display block
        margin-top: 20px
    .social-black a svg
        margin 0px 20px
        height 25px !important
        width 25px !important

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px){

}



.video-background {
  background: black;
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: 13;
  opacity: 0.4
}

iframe#youtube {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: 13;
  opacity: 0.9
  pointer-events: none;
}

.video-foreground-white {
    background: white;
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: 11;
    opacity: 0.5
}

.video-foreground {
    background: black;
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: 12;
    opacity: 0.5
}


iframe#youtube,
.video-foreground,
.video-background iframe {
  background: black;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.wh-full {
    position fixed
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: 12
    opacity: 1
}
</style>

<style lang="stylus" scoped>
@import '../../assets/css/style.styl'

.nav-bg
 top: 0px; right: 0px; bottom: 0px; left: 0px;
 position: absolute;
 background white
 width: 100%
 z-index: 10

.nav-black
  border-bottom: 1px solid $color-gray-border
  width: 100%
  margin: 0
  top: 0
  z-index: 50
  background-color: white

.nav-countdown
  color: white
  width: 100%
  margin: 0
  top: 0
  z-index: 50

.nav-countdown::before {
    content: "";
    top: 0px; right: 25px; bottom: 25px; left: 25px;
    position: absolute;
    display: block;
    background: rgba(0,0,0,0.3);
    box-shadow: 0 0 0.8em 0.8em rgba(0,0,0,0.3);
}

.nav-countdown-black
  color: black
  width: 100%
  margin: 0
  top: 0
  z-index: 50

.nav-countdown-black::before {
    content: "";
    top: 0px; right: 25px; bottom: 25px; left: 25px;
    position: absolute;
    display: block;
    background: white;
}

.fixed
  position: fixed

.z-20
    z-index 20

.social-link
    width: 50px

@media screen and (max-width: 600px)
  .logo
    height: 30px
  .social-link
    width: 50px
    max-height: 20px

  .text-head
    font-size: 1.5rem !important


@media screen and (max-width: 1264px)
  .container
    max-width: 1085px !important
  .social-link
    width: 50px
    max-height: 10px


@media screen and (max-width: 1440px)
  .container
    max-width: 1400px !important

@media screen and (min-width: 1480px)
  .container
    max-width: 1685px !important


@media screen and (max-width: 768px)
  .container
    max-width: 600px !important
</style>
